var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mt-3 mb-3" },
    [
      _c("h2", [_vm._v(_vm._s(_vm.$t("Period Ranges")))]),
      _vm.showForm
        ? _c(
            "vs-button",
            {
              staticClass: "mt-3 mb-3",
              on: {
                click: function($event) {
                  return _vm.onCreate()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("shops.back")))]
          )
        : _vm._e(),
      _c(
        "div",
        { staticClass: "mb-base" },
        [
          _c("data-table", {
            attrs: { data: _vm.tableData, options: _vm.tableOptions },
            on: {
              create: _vm.onCreate,
              remove: _vm.onRemove,
              update: _vm.onUpdate,
              sort: _vm.onSort,
              search: _vm.onSearch,
              "change-page": _vm.onTablePageChange,
              "per-page-change": _vm.onPerPageChange
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "mb-base mt-10" },
        [
          _c("period-range-form", {
            on: { submit: _vm.onFormSubmit },
            model: {
              value: _vm.formData,
              callback: function($$v) {
                _vm.formData = $$v
              },
              expression: "formData"
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }