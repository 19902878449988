<template>
  <div class="mt-3 mb-3">
    <h2>{{ $t("Period Ranges") }}</h2>
    <vs-button class="mt-3 mb-3" v-if="showForm" @click="onCreate()">{{ $t("shops.back") }}</vs-button>

    <div class="mb-base">
      <data-table
        :data="tableData"
        :options="tableOptions"
        @create="onCreate"
        @remove="onRemove"
        @update="onUpdate"
        @sort="onSort"
        @search="onSearch"
        @change-page="onTablePageChange"
        @per-page-change="onPerPageChange"
      />
    </div>

    <div class="mb-base mt-10">
      <period-range-form v-model="formData" @submit="onFormSubmit" />
    </div>
  </div>
</template>

<script>
import DataTable from "@/modules/Shared/Components/table/DataTable";
import PeriodRangeForm from "@/modules/Shops/Pages/forms/PeriodRangeForm.vue";
import Datepicker from "vuejs-datepicker";
import { mapGetters, mapActions } from "vuex";
import notifications from "@/modules/Shared/Mixins/notifications.js";
import i18n from "@/i18n";

export default {
  name: "PeriodRanges",

  mixins: [notifications],

  components: {
    DataTable,
    Datepicker,
    PeriodRangeForm,
  },

  props: {
    period_id: { type: Number, required: true },
  },

  data() {
    return {
      showForm: false,
      formData: {},
    };
  },

  beforeCreate() {
    this.$vs.loading();
  },

  async created() {
    await this.loadPeriodRanges({
      page: 1,
      page_size: 15,
      period_id: this.period_id,
    });
    this.$vs.loading.close();
  },

  computed: {
    ...mapGetters("shops/period_ranges", {
      periodRangesList: "getAll",
      getByPeriodId: "getByPeriodId",
      totalPages: "getTotalPages",
      currentPage: "getCurrentPage",
      pageSize: "getPerPage",
      getById: "getById",
    }),

    ...mapGetters("shops/periods", {
      periodById: "getById",
    }),

    tableData() {
      return this.periodRangesList.map(this.periodRangeToTableData)
    },
    tableOptions() {
      return {
        actions: {
          create: false,
          update: true,
          delete: true,
        },
        pagination: {
          currentPage: this.currentPage,
          totalPages: this.totalPages,
          itemsPerPage: this.pageSize,
        },
        filters: {
          dateRange: true,
        },
      };
    },
  },

  methods: {
    ...mapActions("shops/period_ranges", {
      loadPeriodRanges: "load"
     }),

    periodRangeToTableData(periodRange) {
      return {
        id: periodRange.id,
        from_date: periodRange.from_date,
        to_date: periodRange.to_date,
        monday: periodRange.monday,
        tuesday: periodRange.tuesday,
        wednesday: periodRange.wednesday,
        thursday: periodRange.thursday,
        friday: periodRange.friday,
        saturday: periodRange.saturday,
        sunday: periodRange.sunday,
      };
    },

    async loadPeriodRangesData(params) {
      this.$vs.loading();
      try {
        await this.loadPeriodRanges(params)
      } catch (e) {
        this.$vs.loading.close();
      } finally {
        this.$vs.loading.close();
      }
    },

    async onCreate() {
      this.formData = this.default();
      this.showForm = !this.showForm;
    },

    async createPeriodRange(data) {
      try {
        this.$set(this.formData, "period_id", this.period_id);
        this.$set(data, "period_id", this.period_id);
        const periodRange = await this.$store.dispatch(
          "shops/period_ranges/create",
          data
        );

        this.formData = Object.assign({}, this.formData, periodRange);

        this.notifySuccess(
          i18n.t("shops.save"),
          `${i18n.t('shops.period_range')} ${this.formData.period_id} ${i18n.t('shops.has_been_created_successfully')}`
        );
        await this.loadPeriodRanges({
          page: 1,
          page_size: this.pageSize,
          period_id: this.formData.period_id,
          sort: "+id",
        });
      } catch (error) {
        this.notifyWarning(i18n.t("shops.warning"), i18n.t("shops.failed_to_create_a_period_range"));
      } finally {
        this.formData = this.default();
        this.$vs.loading.close();
      }
    },

    async onFormSubmit(periodRange) {
      try {
        this.$vs.loading();

        if (periodRange.id === 0) {
          // New object, add to API & store
          await this.createPeriodRange(periodRange);
        } else {
          // Existing object, update in API & store
          await this.updatePeriodRange(periodRange);
        }
      } catch (error) {
        this.notifyError(i18n.t("shops.errors.failed_to_save_period_range"), error);
      } finally {
        this.$vs.loading.close();
      }
    },

    async updatePeriodRange(periodRange) {
      try {
        await this.$store.dispatch("shops/period_ranges/update", periodRange);
        await this.loadPeriodRanges({
          page: this.currentPage,
          page_size: this.page_size,
          period_id: this.$props.period_id,
        });

        // Empty form fields
        this.formData = this.default();

        this.showForm = false;
        this.addButton = true;
        this.updateButton = false;

        this.notifySuccess(
          i18n.t("shops.update"),
          `${i18n.t('shops.period_range')} ${periodRange.id} ${i18n.t('shops.has_been_updated_successfully')}`
        );
      } catch (error) {
        this.notifyError(i18n.t("shops.errors.failed_to_update_period_range"), error);
      }
    },

    async onUpdate(periodRange) {
      this.showForm = true;
      this.addButton = false;
      this.updateButton = true;

      this.formData = Object.assign(
        {},
        this.formData,
        this.getById(periodRange.id)
      );

     let currentPeriodRange = await this.getById(periodRange.id)
     let period = await this.periodById(currentPeriodRange.period_id)

      this.formData.period_id = currentPeriodRange.period_id;
      this.formData.period = period;
      await this.loadPeriodRanges({
        page: 1,
        page_size: 15,
        period_id: this.$props.period_id,
      });
    },

    async onRemove(period_range) {
      this.$vs.loading();
      await this.$store.dispatch("shops/period_ranges/remove", period_range);
      this.$vs.loading.close();

      this.notifySuccess(
        i18n.t("shops.removed"),
        i18n.t("shops.period_range") + " " + period_range.id + " " + i18n.t("shops.correctly_removed")
      );

      await this.loadPeriodRanges({
        page: 1,
        page_size: this.pageSize,
        period_id: this.$props.period_id,
      });
    },

    onSort(payload) {
      let order = payload.order === "asc" ? "+" : "-";
      this.loadPeriodRangesData({page: 1, page_size: this.pageSize, sort: order + payload.key, period_id: this.$props.period_id,})
    },

    onSearch(payload) {
      this.loadPeriodRangesData({page: 1, page_size: this.pageSize, q: payload, period_id: this.$props.period_id});
    },

    onTablePageChange(page) {
      this.loadPeriodRangesData({ page, page_size: this.pageSize, period_id: this.$props.period_id});
    },

    onPerPageChange(perPage) {
      this.loadPeriodRangesData({ page: 1, page_size: perPage, period_id: this.$props.period_id});
    },

    default() {
      return {
        id: 0,
        period_id: 0,
        period: {},
        from_date: null,
        to_date: null,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      }
    }
  },
};
</script>

<style>
</style>
