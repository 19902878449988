var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "period-form" },
    [
      _c(
        "div",
        { staticClass: "vx-col w-1/3" },
        [
          _c("label", { staticClass: "vs-input--label" }, [
            _vm._v(_vm._s(_vm.$t("shops.active")))
          ]),
          _c("vs-switch", {
            staticClass: "mt-3",
            attrs: { type: "boolean" },
            model: {
              value: _vm.period.active,
              callback: function($$v) {
                _vm.$set(_vm.period, "active", $$v)
              },
              expression: "period.active"
            }
          })
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "vx-row mb-6" },
        [
          _vm._l(_vm.languages(), function(language, index) {
            return _c(
              "div",
              { key: index, staticClass: "vx-col w-1/3" },
              [
                _c("label", { staticClass: "vs-input--label" }, [
                  _vm._v(_vm._s(_vm.$t("shops.name_" + language)))
                ]),
                _vm.langRequired(language)
                  ? _c("vs-input", {
                      directives: [
                        {
                          name: "validate",
                          rawName: "v-validate",
                          value: "required",
                          expression: "'required'"
                        }
                      ],
                      staticClass: "w-full",
                      attrs: {
                        name: "periodName",
                        danger:
                          _vm.errorPeriodName && _vm.errors.has("periodName"),
                        "danger-text": _vm.$t(
                          "shops.errors.period_name_is_required"
                        )
                      },
                      model: {
                        value: _vm.period.name,
                        callback: function($$v) {
                          _vm.$set(_vm.period, "name", $$v)
                        },
                        expression: "period.name"
                      }
                    })
                  : _c("vs-input", {
                      staticClass: "w-full",
                      attrs: { name: "name" + language },
                      model: {
                        value: _vm.period.translations[language].name,
                        callback: function($$v) {
                          _vm.$set(
                            _vm.period.translations[language],
                            "name",
                            $$v
                          )
                        },
                        expression: "period.translations[language].name"
                      }
                    })
              ],
              1
            )
          }),
          _c(
            "div",
            { staticClass: "vx-col w-1/3" },
            [
              _c("label", { staticClass: "vs-input--label" }, [
                _vm._v(_vm._s(_vm.$t("shops.catalog.0")))
              ]),
              _vm.catalogList.length > 0
                ? _c("v-select", {
                    directives: [
                      {
                        name: "validate",
                        rawName: "v-validate",
                        value: "required",
                        expression: "'required'"
                      }
                    ],
                    staticClass: "w-full select-large",
                    class: {
                      "danger-border-select":
                        _vm.errorCatalog && _vm.errors.has("catalog")
                    },
                    attrs: {
                      options: _vm.catalogMapped,
                      value: _vm.period.catalog_id,
                      name: "catalog",
                      placeholder: _vm.$t("shops.select_a_catalog"),
                      danger: _vm.errorCatalog && _vm.errors.has("catalog"),
                      "danger-text": _vm.$t("shops.errors.catalog_is_required")
                    },
                    on: {
                      input: function(catalog) {
                        return _vm.selectCatalog(catalog)
                      }
                    },
                    model: {
                      value: _vm.actualCatalog.name,
                      callback: function($$v) {
                        _vm.$set(_vm.actualCatalog, "name", $$v)
                      },
                      expression: "actualCatalog.name"
                    }
                  })
                : _vm._e()
            ],
            1
          )
        ],
        2
      ),
      _c(
        "div",
        { staticClass: "vx-col w-1/3" },
        [
          _c(
            "vs-button",
            {
              staticClass: "mb-5",
              attrs: { type: "filled" },
              on: {
                click: function($event) {
                  return _vm.validateAndSubmit()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("shops.save")))]
          )
        ],
        1
      ),
      _vm.period.id != 0
        ? _c(
            "vs-card",
            [_c("period-ranges", { attrs: { period_id: _vm.period.id } })],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }