<template>
  <div>
    <vx-card class="mb-base no-shadow">
      <transition name="fade" mode="out-in">
        <vs-button v-if="showForm" @click="onCreate(); removeFormData();">{{$t("shops.back")}}</vs-button>

        <div class="mb-base" v-if="!showForm">
          <data-table
            class="datatablePeriods"
            :data="tableData"
            :options="tableOptions"
            @create="onCreate"
            @remove="onRemove($event, 'shops.periods.title')"
            @update="onUpdate"
            @sort="onSort"
            @search="onSearch"
            @change-page="onTablePageChange"
            @per-page-change="onPerPageChange"
          />
        </div>
      </transition>

      <transition name="fade" mode="out-in">
        <div class="mb-base mt-10" v-if="showForm">
          <period-form v-model="formData" @submit="onPeriodFormSubmit" />
        </div>
      </transition>
    </vx-card>
  </div>
</template>

<script>
import i18n from "@/i18n";
import { mapGetters, mapActions } from "vuex";
import DataTable from "@/modules/Shared/Components/table/DataTable";
import PeriodForm from "@/modules/Shops/Pages/forms/PeriodForm.vue";
import notifications from "@/modules/Shared/Mixins/notifications.js";
import shopLanguages from "@/modules/Shared/Mixins/shopLanguages.js";
import table_functions from "@/modules/Shared/Mixins/table_functions.js";

export default {
  name: "Periods",

  mixins: [ notifications, table_functions, shopLanguages ],

  components: {
    DataTable,
    PeriodForm,
  },

  data() {
    return {
      showForm: false,
      formData: {},
      exportPayload: {}
    };
  },

  computed: {
    ...mapGetters("shops/periods", {
      periodList: "getAll",
      totalPages: "getTotalPages",
      currentPage: "getCurrentPage",
      perPage: "getPerPage",
      periodById: "getById",
    }),

    ...mapGetters("shops/catalogs", {
      catalogs: "getAll",
    }),

    tableData() {
      return this.periodList.map(this.periodToTableData);
    },

    tableOptions() {
      return {
        actions: {
          create: true,
          update: true,
          delete: true,
        },
        pagination: {
          currentPage: this.currentPage,
          totalPages: this.totalPages,
          itemsPerPage: this.perPage,
        },
        filters: {
          dateRange: false,
        },
      };
    },
  },

  created() {
    this.$store.commit("shops/periods/RESET_RESOURCE");
    this.exportPayload.marketing = this.$route.name === 'MarketingPeriods';
    this.loadData({marketing: this.$route.name === 'MarketingPeriods'})
  },

  methods: {
    ...mapActions("shops/periods", {
      loadData: "load",
      createData: "create",
      updateData: "update",
      removeData: "remove"
    }),

    periodToTableData(period) {
      return {
        id: period.id,
        active: period.active,
        name: period.name,
      };
    },

    removeFormData() {
      this.formData = this.default();
    },

    async createPeriod(data) {
      this.$vs.loading();
      this.showForm = false
      try {
        const period = await this.createData(data);
        this.formData = Object.assign({}, this.formData, period);
        this.notifySuccess(i18n.t("shops.save"), `${i18n.t("shops.period")} ${this.formData.name} ${i18n.t("shops.has_been_created_successfully")}`);
        this.showForm = true
      } catch (error) {
        this.notifyWarning(i18n.t("shops.warning"), i18n.t("shops.failed_to_create_a_period"));
        this.$vs.loading.close();
      } finally {
        this.$vs.loading.close();
      }
    },

    async onPeriodFormSubmit(period) {
      this.$vs.loading();
      try {
        if (period.id === 0) {
          // New object, add to API & store
          await this.createPeriod(period);
        } else {
          // Existing object, update in API & store
          await this.update(period);
        }
      } catch (error) {
        this.notifyError(i18n.t("shops.errors.failed_to_save_period"), error);
        this.$vs.loading.close();
      } finally {
        this.$vs.loading.close();
      }
    },

    async update(period) {
      this.$vs.loading();
      try {
        await this.updateData(period);

        // Empty form fields
        this.removeFormData();
        this.showForm = false;
        this.addButton = true;
        this.updateButton = false;
      } catch (error) {
        this.notifyError(i18n.t("shops.errors.failed_to_update_period"), error);
        this.$vs.loading.close();
      } finally {
        this.notifySuccess(
          i18n.t("shops.update"),
          `${i18n.t("shops.period")} ${period.name} ${i18n.t(
            "shops.has_been_updated_successfully"
          )}`
        );
        this.$vs.loading.close();
      }
    },

    async onUpdate(period) {
      const translationsDefault = this.translationsDefault(['name'])

      this.formData = Object.assign({},this.formData, {
        ...await this.periodById(period.id),
        translations: { ...translationsDefault, ...this.periodById(period.id).translations }
      });
      
      this.addButton = false;
      this.updateButton = true;
      this.showForm = true;
    },

    default() {
      return {
        id: 0,
        name: "",
        active: false,
        catalog_id: null,
        translations: this.translationsDefault(['name']),
        marketing: null
      }
    }
  }
};
</script>

<style>
.datatablePeriods th:nth-child(1) {
  display: none;
}
.datatablePeriods td:nth-child(1) {
  display: none;
}

.vs-con-table .vs-con-tbody .con-vs-checkbox {
  justify-content: left !important;
}

.datatablePeriods .vs-table--header {
  @apply flex;
  @apply flex-wrap;
}
.datatablePeriods .vs-button {
  @apply order-3;
}
.datatablePeriods .con-input-search {
  @apply order-2;
  @apply mr-auto;
  @apply ml-4;
}
</style>
