<template>
  <div class="period-range-form">
    <div class="vx-row mb-6">
      <div class="vx-col w-1/4">
        <label class="vs-input--label">{{ $t("shops.from_date") }}</label>
        <datepicker
          :class="{ 'danger-border': errorFromDate && errors.has('from_date') }"
          :mondayFirst="true"
          :placeholder="$t('shops.select_from_date')"
          v-validate="'required'"
          v-model="periodRange.from_date"
          name="from_date"
        ></datepicker>
        <div class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to">
          <span v-if="errorFromDate && errors.has('from_date')">{{ $t("shops.errors.from_date_is_required") }}</span>
        </div>
      </div>
      <div class="vx-col w-1/4">
        <label class="vs-input--label">{{ $t("shops.to_date") }}</label>
        <datepicker
          :class="{ 'danger-border': errorToDate && errors.has('to_date') }"
          :mondayFirst="true"
          :placeholder="$t('shops.select_to_date')"
          v-validate="'required'"
          v-model="periodRange.to_date"
          name="to_date"
        ></datepicker>
        <div class="con-text-validation span-text-validation-danger vs-input--text-validation-span v-enter-to">
          <span v-if="errorToDate && errors.has('to_date')">{{ $t("shops.errors.to_date_is_required") }}</span>
        </div>
      </div>
    </div>
    <div class="vx-row mb-6">
      <div v-for="(day, index) in weekdays" :key="index" class="vx-col w-1/7">
        <label class="vs-input--label">{{ $t(`shops.${day}`) }}</label>
        <vs-switch class="mt-3" v-model="periodRange[day]" type="boolean" />
      </div>
    </div>
    <vs-divider />
    <vs-button @click="validateAndSubmit()" class="mt-base" type="filled">{{ $t("shops.save") }}</vs-button>
  </div>
</template>

<script>
import i18n from "@/i18n";
import { mapGetters } from "vuex";
import Datepicker from "vuejs-datepicker";
import { weekdays } from "@/modules/Shared/Helpers/dateHelper.js"
import notifications from "@/modules/Shared/Mixins/notifications.js";

export default {
  name: "PeriodRangeForm",

  mixins: [notifications],

  components: {
    Datepicker,
  },

  props: {
    value: { type: Object, default: null },
  },

  data() {
    return {
      errorFromDate: false,
      errorToDate: false,
      errorPeriod: false,
      periodRange: {},
      weekdays: [] 
    };
  },

  computed: {
    ...mapGetters("shops/periods", {
      periodList: "getAll",
      periodById: "getById"
     })
  },

  watch: {
    value: function() {
      this.periodRange = JSON.parse(JSON.stringify(this.value))
    }
  },

  methods: {
    validateAndSubmit() {
      const payload = { ...this.weekdays.reduce((carry, day) => {
          carry[day] = this.periodRange.hasOwnProperty(day) ? this.periodRange.day : false
          return carry
        }, {}), ...this.periodRange }

      this.$validator.validateAll().then((result) => {
      if (result) {
        this.$emit("submit", payload);
        this.periodRange = this.default()
      } else {
        this.notifyWarning(i18n.t("shops.warning"), i18n.t("shops.period_ranges_validation_failed"));
        if (!this.periodRange.from_date) this.errorFromDate = true;
        if (!this.periodRange.to_date) this.errorToDate = true;
      }
    });
    },
    selectPeriod(option) {
      this.periodRange.period_id = option.value;
    },
    default() {
      return {
        id: 0,
        period_id: 0,
        period: {},
        from_date: null,
        to_date: null,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false,
        sunday: false,
      }
    }
  },
  async created() {
    this.periodRange = JSON.parse(JSON.stringify({ ...this.value, ...this.default() }))
    this.weekdays = weekdays()
    this.periodRange.period.id = await this.periodById(this.periodRange.period_id)
    if (this.periodRange.period.id == undefined) this.periodRange.period = {}
  }
};
</script>
