<template>
  <div class="period-form">
    <div class="vx-col w-1/3">
      <label class="vs-input--label">{{ $t("shops.active") }}</label>
      <vs-switch class="mt-3" v-model="period.active" type="boolean" />
    </div>

    <div class="vx-row mb-6">
      <div v-for="(language, index) in languages()" :key="index" class="vx-col w-1/3">
        <label class="vs-input--label">{{ $t(`shops.name_${language}`) }}</label>
        <vs-input v-if="langRequired(language)"
          v-model="period.name"
          v-validate="'required'"
          class="w-full"
          name="periodName"
          :danger="errorPeriodName && errors.has('periodName')"
          :danger-text="$t('shops.errors.period_name_is_required')" />
        <vs-input v-else v-model="period.translations[language].name" class="w-full" :name="`name${language}`" />
      </div>
      
      <div class="vx-col w-1/3">
        <label class="vs-input--label">{{ $t("shops.catalog.0") }}</label>
        <v-select
          v-if="catalogList.length > 0"
          v-model="actualCatalog.name"
          :options="catalogMapped"
          @input="(catalog) => selectCatalog(catalog)"
          class="w-full select-large"
          :class="{ 'danger-border-select':errorCatalog && errors.has('catalog') }"
          :value="period.catalog_id"
          name="catalog"
          :placeholder="$t('shops.select_a_catalog')"
          v-validate="'required'"
          :danger="errorCatalog && errors.has('catalog')"
          :danger-text="$t('shops.errors.catalog_is_required')"
        />
      </div>

    </div>

    <div class="vx-col w-1/3">
      <vs-button @click="validateAndSubmit()" class="mb-5" type="filled">{{ $t("shops.save") }}</vs-button>
    </div>
    <vs-card v-if="period.id != 0">
      <period-ranges :period_id="period.id" />
    </vs-card>
  </div>
</template>

<script>
import i18n from "@/i18n";
import { mapGetters, mapActions } from "vuex";
import notifications from "@/modules/Shared/Mixins/notifications.js";
import shopLanguages from "@/modules/Shared/Mixins/shopLanguages.js";
import PeriodRanges from "@/modules/Shops/Pages/data/PeriodRanges.vue";

export default {
  name: "PeriodForm",

  props: {
    value: { type: Object, default: null },
  },

  mixins: [ notifications, shopLanguages ],

  components: {
    PeriodRanges,
  },

  data() {
    return {
      errorCatalog: false,
      errorPeriodName: false,
      errorPeriodCode: false,
      actualCatalog: {},
      period: {}
    };
  },

  computed: {
    ...mapGetters("shops/catalogs", {
      catalogList: "getAll",
      catalogById: "getById"
    }),
    catalogMapped() {
      return this.catalogList.map((catalog) => ({ label: catalog.name, value: catalog.id }))
    }
  },

  methods: {
    ...mapActions("shops/catalogs", { loadCatalogs: "load" }),
    validateAndSubmit() {
      const payload = { ...this.languages().reduce((carry, lang) => {
            carry[lang] = this.period.translations[lang]
            return carry
        }, {}), ...this.period }

      payload.marketing = this.$route.name === 'MarketingPeriods'

      this.$validator.validateAll().then(result => {
          if (result) {
            this.$emit("submit", payload);
          } else {
            this.notifyWarning(i18n.t("shops.warning"), i18n.t("shops.period_validation_failed"));
            if (!this.period.name) this.errorPeriodName = true;
            if (!this.period.catalog_id) this.errorCatalog = true;
          }
        });
    },
    selectCatalog(option) {
      this.period.catalog_id = option === null ? option : option.value;
    },
  },
  async created(){
    this.period = JSON.parse(JSON.stringify(this.value))

    let periodCatalog = await this.catalogById(this.period.catalog_id)

    await this.loadCatalogs();

    this.actualCatalog = periodCatalog === undefined ? {} : periodCatalog
  }
};
</script>

<style>
</style>
